<template>
    <div class="contact-page" :class="{ 'rtl': isArabic }">
      <h1 class="contact-title">{{ $t('contactUs') }}</h1>
      <div class="contact-content">
        <img
          src="https://images.pexels.com/photos/3182812/pexels-photo-3182812.jpeg"
          alt="Contact Us"
          class="contact-image"
        />
        <p>{{ $t('contactDescription') }}</p>
        
        <form @submit.prevent="submitForm" class="contact-form">
          <div class="form-group">
            <label for="name">{{ $t('name') }}</label>
            <input
              type="text"
              id="name"
              v-model="form.name"
              :placeholder="$t('enterName')"
              required
            />
          </div>
  
          <div class="form-group">
            <label for="email">{{ $t('email') }}</label>
            <input
              type="email"
              id="email"
              v-model="form.email"
              :placeholder="$t('enterEmail')"
              required
            />
          </div>
  
          <div class="form-group">
            <label for="message">{{ $t('message') }}</label>
            <textarea
              id="message"
              v-model="form.message"
              :placeholder="$t('enterMessage')"
              required
            ></textarea>
          </div>
  
          <button type="submit" class="submit-btn">{{ $t('submit') }}</button>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "ContactPage",
    data() {
      return {
        form: {
          name: "",
          email: "",
          message: "",
        },
      };
    },
    computed: {
      isArabic() {
        return this.$i18n.locale === "ar";
      },
    },
    methods: {
      submitForm() {
        alert(this.$t('formSubmitted'));
        // Handle form submission logic
      },
    },
  };
  </script>
  
  <style scoped lang="less">
  .contact-page {
    max-width: 800px;
    margin: 30px auto;
    padding: 20px;
    background: white;
    border-radius: 8px;
  
    &.rtl {
      direction: rtl;
      text-align: right;
    }
  
    .contact-title {
      font-size: 28px;
      font-weight: bold;
      margin-bottom: 20px;
    }
  
    .contact-content {
      .contact-image {
        width: 100%;
        border-radius: 8px;
        margin-bottom: 20px;
      }
  
      .contact-form {
        .form-group {
          margin-bottom: 15px;
  
          label {
            font-size: 16px;
            display: block;
            margin-bottom: 5px;
          }
  
          input,
          textarea {
            width: 100%;
            padding: 10px;
            border: 1px solid #ccc;
            border-radius: 8px;
            font-size: 16px;
          }
  
          textarea {
            resize: none;
          }
        }
  
        .submit-btn {
          background-color: #007bff;
          color: white;
          padding: 10px 20px;
          border: none;
          border-radius: 8px;
          font-size: 16px;
          cursor: pointer;
  
          &:hover {
            background-color: #0056b3;
          }
        }
      }
    }
  }
  </style>
  