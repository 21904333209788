<template>
  <div class="order" :class="{ 'rtl': isArabic }">
    <form>
      <div class="order-type">
        <p class="section-title">{{ $t('orderType') }}</p>
        <div class="radio-container">
          <div class="radio-options">
            <input
              type="radio"
              name="order-type"
              id="table"
              value="table"
              v-model="orderType"
            />
            <label for="table">{{ $t('table') }}</label>
          </div>
          <div class="radio-options">
            <input
              type="radio"
              name="order-type"
              id="delivery"
              value="delivery"
              v-model="orderType"
            />
            <label for="delivery">{{ $t('delivery') }}</label>
          </div>
        </div>
      </div>

      <div v-if="orderType === 'table'" class="table-data">
        <p class="section-title">{{ $t('tableNumber') }}</p>
        <div class="input-field">
          <label for="tableNumber">{{ $t('tableNumberLabel') }}</label>
          <input
            type="text"
            id="tableNumber"
            v-model="tableNumber"
            :placeholder="$t('tableNumberPlaceholder')"
            :class="{ error: !tableNumberValid }"
            @blur="validateTableNumber"
          />
          <p v-if="!tableNumberValid" class="error-message">
            {{ $t('tableNumberError') }}
          </p>
        </div>
      </div>

      <div v-if="orderType === 'delivery'" class="user-data">
        <p class="section-title">{{ $t('yourData') }}</p>
        <div class="input-field">
          <label for="">{{ $t('nameLabel') }}</label>
          <input
            type="text"
            :placeholder="$t('namePlaceholder')"
            v-model="formData.name.value"
            @blur="formData.name.isValid"
            :class="{ error: !formData.name.valid }"
          />
          <p v-if="!formData.name.valid" class="error-message">
            {{ $t('nameError') }}
          </p>
        </div>
        <div class="input-field">
          <label for="">{{ $t('cellphoneLabel') }}</label>
          <input
            type="text"
            :placeholder="$t('cellphonePlaceholder')"
            v-model="formData.cellphone.value"
            @blur="formData.cellphone.isValid"
            :class="{ error: !formData.cellphone.valid }"
          />
          <p v-if="!formData.cellphone.valid" class="error-message">
            {{ $t('cellphoneError') }}
          </p>
        </div>
        <div class="input-field">
          <label for="">{{ $t('addressLabel') }}</label>
          <input
            type="text"
            :placeholder="$t('addressPlaceholder')"
            v-model="formData.address.value"
            @blur="formData.address.isValid"
            :class="{ error: !formData.address.valid }"
          />
          <p v-if="!formData.address.valid" class="error-message">
            {{ $t('addressError') }}
          </p>
        </div>
      </div>
    </form>
    <button class="primary-button finish-payment-button" @click="orderItems">
      {{ $t('completeOrder') }}
    </button>

    <Modal :show="showInvalidAddressModal" @on-modal-close="hideInvalidAddressModal">
      <InvalidAddressModal />
    </Modal>

    <Modal :show="showEmptyCartModal" @on-modal-close="hideEmptyCartModal">
      <EmptyCartModal />
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal";
import InvalidAddressModal from "./InvalidAddressModal";
import EmptyCartModal from "./EmptyCartModal";

export default {
  name: "OrderForm",
  components: {
    Modal,
    InvalidAddressModal,
    EmptyCartModal,
  },
  data() {
    return {
      orderType: "table", // Default to table
      tableNumber: "",
      tableNumberValid: true,
      formData: {
        name: {
          value: "",
          valid: true,
          isValid: () => {
            this.formData.name.valid = !!this.formData.name.value.length;
          },
        },
        cellphone: {
          value: "",
          valid: true,
          isValid: () => {
            const regex = /^07\d{9}$/; // Matches numbers starting with 07 and 10 digits long
            this.formData.cellphone.valid = regex.test(this.formData.cellphone.value);
          },
        },
        address: {
          value: "",
          valid: true,
          isValid: () => {
            this.formData.address.valid = !!this.formData.address.value.length;
          },
        },
      },
      showInvalidAddressModal: false,
      showEmptyCartModal: false,
    };
  },
  computed: {
    isUserFormDataValid() {
      return this.formData.name.valid && this.formData.cellphone.valid;
    },
    isAddressFormDataValid() {
      return this.formData.address.valid;
    },
    isDeliveryType() {
      return this.orderType === "delivery";
    },
    isArabic() {
      return this.$i18n.locale === 'ar';
    }
  },
  methods: {
    validateTableNumber() {
      this.tableNumberValid = !!this.tableNumber;
    },
    triggerValidations() {
      if (this.orderType === 'table') {
        this.validateTableNumber();
      } else {
        this.formData.name.isValid();
        this.formData.cellphone.isValid();
        this.formData.address.isValid();
        if (!this.isAddressFormDataValid) {
          this.showInvalidAddressModal = true;
        }
      }
      if (this.$store.state.cartList.length === 0) {
        this.showEmptyCartModal = true;
      }
    },
    orderItems() {
      this.triggerValidations();
      if (
        (this.orderType === 'table' && !this.tableNumberValid) ||
        (this.orderType === 'delivery' && (!this.isUserFormDataValid || !this.isAddressFormDataValid)) ||
        this.$store.state.cartList.length === 0
      )
        return;

      const phone = "+9647717432922";
      let orderDetails = this.orderType === 'table'
        ? `${this.$t('tableNumber')}: ${this.tableNumber}`
        : `${this.$t('address')}: ${this.formData.address.value}`;

      let text = `*${this.$t('restaurantName')}*
      ${this.$t('client')}: ${this.formData.name.value || ''}
      ${this.$t('contact')}: ${this.formData.cellphone.value || ''}
      ${orderDetails}
      ${this.$t('order')}: ${this.$store.state.cartList.map((item) => {
        return item.quantity > 0 ? `${item.quantity}x ${item.name.ar} - ${item.observations || ""}` : '';
      }).filter(Boolean).join('\n')}`;

      // Remove empty fields from the message
      text = text.replace(/Cliente: \s*$/gm, '');
      text = text.replace(/Contato: \s*$/gm, '');

      text = window.encodeURIComponent(text);
      window.open(`https://api.whatsapp.com/send?phone=${phone}&text=${text}`);

      this.$store.dispatch("resetCartList");
      this.$router.push({ name: "Home" });
    },
    hideInvalidAddressModal() {
      this.showInvalidAddressModal = false;
    },
    hideEmptyCartModal() {
      this.showEmptyCartModal = false;
    },
  },
};
</script>

<style scoped lang="less">
.order {
  max-width: 720px;
  margin: 30px auto;
  background: white;
  border-radius: 8px;
  padding: 30px 50px;

  &.rtl {
    direction: rtl;
    text-align: right;
  }

  .input-field {
    display: flex;
    flex-direction: column;

    & + .input-field {
      margin-top: 15px;
    }

    label {
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 5px;
    }

    .error-message {
      font-size: 12px;
      color: @error-color;
      margin-bottom: 0;
      margin-top: 8px;
    }
  }

  form {
    display: flex;
    flex-direction: column;

    .section-title {
      font-weight: 600;
      font-size: 22px;
      margin-bottom: 14px;
      margin-top: 30px;
    }

    .radio-container {
      display: flex;
    }

    .radio-options {
      display: flex;
      align-items: center;

      input {
        margin: 0;
      }

      label {
        padding-left: 10px;
        padding-right: 10px;
        margin: 0;
        font-size: 14px;
      }

      & + .radio-options {
        margin-left: 25px;
      }
    }
  }

  .finish-payment-button {
    margin: 30px auto;
  }
}

@media @small-desktops {
  .order {
    width: 100%;
    max-width: 600px;
    padding: 20px;

    .finish-payment-button {
      width: 100%;
    }
  }

  form {
    .user-data {
      .section-title {
        margin-top: 0;
      }
    }
  }
}

@media @smartphones {
  .order {
    padding: 0 20px;
    margin-bottom: 0;

    .finish-payment-button {
      margin-bottom: 0;
    }
  }
}
</style>