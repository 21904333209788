<template>
    <div class="about-page" :class="{ 'rtl': isArabic }">
      <h1 class="about-title">{{ $t('aboutUs') }}</h1>
      <div class="about-content">
        <img src="https://images.pexels.com/photos/1640777/pexels-photo-1640777.jpeg" alt="Restaurant Interior" class="about-image" />
        <p>{{ $t('aboutDescription') }}</p>
        <h2>{{ $t('mission') }}</h2>
        <p>{{ $t('missionStatement') }}</p>
        <h2>{{ $t('specialties') }}</h2>
        <ul>
          <li>{{ $t('specialty1') }}</li>
          <li>{{ $t('specialty2') }}</li>
          <li>{{ $t('specialty3') }}</li>
        </ul>
        <h2>{{ $t('funFacts') }}</h2>
        <ul>
          <li>{{ $t('fact1') }}</li>
          <li>{{ $t('fact2') }}</li>
          <li>{{ $t('fact3') }}</li>
        </ul>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "AboutPage",
    computed: {
      isArabic() {
        return this.$i18n.locale === 'ar';
      }
    }
  };
  </script>
  
  <style scoped lang="less">
  .about-page {
    max-width: 800px;
    margin: 30px auto;
    padding: 20px;
    background: white;
    border-radius: 8px;
  
    &.rtl {
      direction: rtl;
      text-align: right;
    }
  
    .about-title {
      font-size: 28px;
      font-weight: bold;
      margin-bottom: 20px;
    }
  
    .about-content {
      .about-image {
        width: 100%;
        border-radius: 8px;
        margin-bottom: 20px;
      }
  
      h2 {
        font-size: 22px;
        margin-top: 20px;
        margin-bottom: 10px;
      }
  
      ul {
        list-style-type: disc;
        padding-left: 20px;
      }
    }
  }
  </style>