<template>
  <div class="item" @click="addToCart" :class="{ 'rtl': isArabic }">
    <div class="container">
      <div class="item--tag" v-if="item.offer">{{ $t('offer') }}</div>
      <img class="item--img" :src="imagePath" alt="" />
    </div>
    <div class="content">
      <h2 class="item--name">{{ localizedName }}</h2>
      <p class="item--description">{{ localizedDescription }}</p>
      <p class="item--price">{{ formatCurrency(item.price) }}</p>
    </div>
  </div>
</template>

<script>
import Mixin from "@/mixins/mixins";

export default {
  name: "Item",
  mixins: [Mixin],
  props: {
    item: {
      type: Object,
      required: true
    },
  },
  computed: {
    selectedCategory() {
      return this.$store.state.selectedCategory;
    },
    imagePath() {
      if (!this.item?.id) return;
      return require(`@/assets/images/${this.item.id}.png`);
    },
    localizedName() {
      return this.item.name[this.$i18n.locale] || this.item.name.en;
    },
    localizedDescription() {
      return this.item.description[this.$i18n.locale] || this.item.description.en;
    },
    isArabic() {
      return this.$i18n.locale === 'ar';
    }
  },
  methods: {
    addToCart() {
      if (this.isDesktop()) {
        this.$store.dispatch("addToCart", this.item);
        return;
      }
      if (this.$router.history.current.name !== "AddToCart") {
        this.$router.push({ name: "AddToCart", params: { id: this.item.id } });
      }
    },
    formatCurrency(value) {
      if (!value) return;
      return value.toLocaleString(this.$i18n.locale, {
        style: 'currency',
        currency: 'IQD',
        minimumFractionDigits: 0,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.item {
  width: 216px;
  height: 250px;
  border-radius: 8px;
  background: white;
  position: relative;
  margin: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  &--tag {
    position: absolute;
    background: @pink;
    border-radius: 12px;
    color: white;
    top: 15px;
    inset-inline-end: 15px; // Use logical property for RTL support
    font-weight: 500;
    font-size: 12px;
    padding: 3px 8px;
  }

  &--img {
    display: block;
    margin: auto;
    width: 65%;
  }

  &--name {
    font-weight: 600;
    font-size: 16px;
    margin: 0;
  }

  &--description {
    color: @dark-grey;
    font-weight: 300;
    font-size: 12px;
    margin: 0;
  }

  &--price {
    font-weight: 600;
    font-size: 18px;
    color: @yellow;
    margin: 0;
  }

  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 10px;
  }

  &.rtl {
    direction: rtl;
    text-align: right;

    .item--tag {
      inset-inline-start: 15px;
      inset-inline-end: auto;
    }
  }
}

@media @smartphones {
  .item {
    width: 100%;
    height: fit-content;
    border: 1px solid @light-grey;
    display: flex;
    flex-direction: row;
    margin: 10px 0;
    padding: 10px 20px;

    &--tag {
      position: static;
      order: 1;
      width: fit-content;
    }

    &--img {
      width: 86px;
      order: 0;
      margin-bottom: 10px;
      margin-top: 0;
    }

    &--price {
      text-align: end;
      margin: 5px 0 0 auto;
    }

    .content {
      flex-grow: 1;
    }

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-inline-end: 10px;
    }

    &.rtl {
      flex-direction: row-reverse;

      .container {
        margin-inline-start: 10px;
        margin-inline-end: 0;
      }

      .item--price {
        margin: 5px auto 0 0;
      }
    }
  }
}
</style>