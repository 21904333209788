<template>
  <div id="app">
    <nav v-if="showNavbar" class="navbar" :dir="currentDirection">
      <div class="logo">
        <img src="https://image.similarpng.com/very-thumbnail/2021/07/Chef-restaurant-logo-illustrations-template-on-transparent-background-PNG.png" alt="Restaurant Logo" />
      </div>
      <div :class="['burger', { 'left': currentLanguage === 'العربية', 'right': currentLanguage === 'English' }]"  @click="toggleMenu">
        <div :class="['line', { 'active': isMenuOpen }]"></div>
        <div :class="['line', { 'active': isMenuOpen }]"></div>
        <div :class="['line', { 'active': isMenuOpen }]"></div>
      </div>
      <ul :class="['nav-links', { 'active': isMenuOpen }]">
        <li><router-link to="/menu">{{ $t('menu') }}</router-link></li>
        <li><router-link to="/about">{{ $t('about') }}</router-link></li>
        <li><router-link to="/contact">{{ $t('contact') }}</router-link></li>
        <li class="language-dropdown">
          <button @click="toggleDropdown" class="dropdown-button">
            <img :src="currentFlag" alt="Language Flag" class="flag-icon" />
            {{ currentLanguage }}
            <span class="arrow" :class="{ 'up': dropdownOpen }">▼</span>
          </button>
          <ul v-if="dropdownOpen" class="dropdown-menu">
            <li @click="setLanguage('en')" class="dropdown-item">
              <img src="https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg" alt="English" class="flag-icon" />
              English
            </li>
            <li @click="setLanguage('ar')" class="dropdown-item">
              <img src="https://upload.wikimedia.org/wikipedia/commons/f/f6/Flag_of_Iraq.svg" alt="Arabic" class="flag-icon" />
              العربية
            </li>
          </ul>
        </li>
      </ul>
    </nav>
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      isMenuOpen: false,
      dropdownOpen: false,
    };
  },
  computed: {
    showNavbar() {
      return this.$route.path !== '/';
    },
    currentLanguage() {
      return this.$i18n.locale === 'en' ? 'English' : 'العربية';
    },
    currentFlag() {
      return this.$i18n.locale === 'en'
        ? 'https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg'
        : 'https://upload.wikimedia.org/wikipedia/commons/f/f6/Flag_of_Iraq.svg';
    },
    currentDirection() {
      return this.$i18n.locale === 'ar' ? 'rtl' : 'ltr';
    },
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    setLanguage(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem('language', lang);
      this.dropdownOpen = false; // Close dropdown after selection
    },
  },
  watch: {
    '$route'(to) {
      if (this.isMenuOpen) {
        this.isMenuOpen = false;
      }
    }
  }
};
</script>

<style scoped>
/* Navbar Styles */
.navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #2c3e50; /* Darker background for contrast */
  padding: 15px 30px; /* Increased padding for a more spacious look */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  position: sticky;
  top: 0;
  z-index: 1000;
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}

.navbar:hover {
  background-color: #34495e; /* Slightly lighter on hover */
}

.logo img {
  height: 50px;
}

.burger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.line {
  width: 30px; /* Increased width for better visibility */
  height: 3px;
  background-color: #ecf0f1; /* Light color for contrast */
  margin: 4px 0;
  transition: all 0.3s ease;
}

.line.active {
  background-color: #FF2351; /* Highlight color when active */
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 30px; /* Increased gap for better spacing */
}

.nav-links li {
  font-size: 18px; /* Increased font size for better readability */
  font-weight: 500;
}

.nav-links a {
  text-decoration: none;
  color: #ecf0f1; /* Light color for links */
  transition: color 0.3s;
}

.nav-links a:hover {
  color: #FF2351; /* Highlight color on hover */
}

.language-dropdown {
  position: relative;
}

.dropdown-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #ecf0f1; /* Light color for dropdown button */
}

.flag-icon {
  width: 25px; /* Slightly larger flag icon */
  height: 25px;
  margin: 0 5px; /* Adjusted margin for better spacing */
}

.arrow {
  margin-left: 5px;
  margin-right: 5px;
  transition: transform 0.3s;
}

.arrow.up {
  transform: rotate(180deg);
}

.dropdown-menu {
  position: absolute;
  top: 100%;
 
  background: #fff; /* White background for dropdown */
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  list-style: none;
  padding: 10px 0 10px 0;
  width: 150px; /* Set a fixed width for the dropdown */
  border-radius: 5px; /* Rounded corners for dropdown */
}

.dropdown-item {
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s; /* Smooth transition for background color */
}

.dropdown-item:hover {
  background-color: #f0f0f0; /* Light gray on hover */
}

.cart-icon {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background: #FF2351; /* Cart icon color */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}

@media (max-width: 767px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
  }

  .burger {
    display: flex;
    /* Default to left alignment */
    margin-right: auto; /* Align to the left by default */
  }
  
  .burger.right {
    margin-left: 90%; /* Align to the right when Arabic */
  }

  .nav-links {
    flex-direction: column;
    gap: 10px;
    width: 100%;
    padding: 10px 0;
    display: none; /* Hide by default */
  }

  .nav-links.active {
    display: flex; /* Show when active */
  }

  .cart-icon {
    margin-top: 10px;
  }
}
</style>