// src/i18n.js
import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
  ar: {
    chooseLanguage: 'اختر لغتك',
    helloWorld: 'مرحبا بالعالم!',
    categories: {
      pizza: 'بيتزا',
      drinks: 'مشروبات',
      desserts: 'حلويات',
      combo: 'كومبو',
      burgers: 'برجر',
    },
    "removeItemConfirmation": "هل تريد إزالة هذا العنصر من السلة؟",
    "yesRemove": "نعم، إزالة",
    "orderType": "نوع الطلب",
    "yourData": "بياناتك",
    "nameLabel": "الاسم*",
    "namePlaceholder": "أدخل اسمك",
    "nameError": "الاسم مطلوب",
    "cellphoneLabel": "الهاتف المحمول*",
    "cellphonePlaceholder": "أدخل هاتفك المحمول",
    "cellphoneError": "الهاتف المحمول مطلوب ويجب أن يبدأ بـ 07",
    "addressLabel": "العنوان*",
    "addressPlaceholder": "أدخل عنوانك",
    "addressError": "العنوان مطلوب",
    address : "العنوان",
    offer: 'عرض',
    currency: 'د.ع', // IQD symbol in Arabic
    menu: 'قائمة الطعام',
    order: 'اطلب الآن',
    contact: 'اتصل بنا',
    about: 'من نحن',
    videoNotSupported: 'متصفحك لا يدعم عنصر الفيديو.',
    welcomeMessage: 'مرحبًا بكم في مطعمنا!',
    addObservation: "إضافة ملاحظة",
    cancel: "إلغاء",
    save: "حفظ",
    goBack: "العودة",
    addToCart: "أضف إلى السلة",
    quantity: "الكمية",
    observations: "ملاحظات",
    yourOrder: "طلبك",
    cartEmpty: "سلة التسوق فارغة :(",
    total: "المجموع",
    checkout: "إتمام الشراء",
    table: "طاولة",
    delivery: "توصيل",
    tableNumber: "رقم الطاولة",
    tableNumberLabel: "رقم الطاولة*",
    tableNumberPlaceholder: "أدخل رقم الطاولة",
    tableNumberError: "رقم الطاولة مطلوب",
    streetLabel: "الشارع*",
    streetPlaceholder: "أدخل اسم الشارع",
    streetError: "الشارع مطلوب",
    cityLabel: "المدينة*",
    cityPlaceholder: "أدخل اسم المدينة",
    cityError: "المدينة مطلوبة",
    cepLabel: "الرمز البريدي*",
    cepPlaceholder: "أدخل الرمز البريدي",
    cepError: "الرمز البريدي مطلوب",
    restaurantName: "مطعم ديب",
    client: "العميل",
    contact: "الاتصال",
    order: "الطلب",
    editAddress: "تعديل العنوان",
    addAddress: "إضافة عنوان",
    payment: "الدفع",
    paymentMethod: "طريقة الدفع",
    creditCard: "بطاقة ائتمان",
    cash: "نقداً",
    completeOrder: "إتمام الطلب",
    invalidAddressMessage: "مطلوب عنوان صالح للتوصيل.",
    emptyCartMessage: "سلة التسوق فارغة.",
    "aboutUs": "من نحن",
    "aboutDescription": "مرحبًا بكم في مطعم ديب، حيث تتحقق أحلام الطهي! يقع في قلب المدينة، نقدم مزيجًا رائعًا من الأطباق التقليدية والحديثة التي تناسب جميع الأذواق. يستخدم طهاتنا أفضل المكونات الطازجة لإنشاء وجبات لذيذة ستجعلك ترغب في المزيد.",
    "mission": "مهمتنا",
    "missionStatement": "في مطعم ديب، مهمتنا هي تقديم تجربة تناول طعام لا تُنسى تجمع بين الطعام الاستثنائي، والضيافة الدافئة، وأجواء مريحة. نؤمن باستخدام المكونات المحلية ودعم مجتمعنا.",
    "specialties": "تخصصاتنا",
    "specialty1": "برجر ديب المميز - شريحة لحم بقري طرية مغطاة بجبنة شيدر، ولحم مقدد مقرمش، وصلصة خاصة.",
    "specialty2": "مكرونة الترافل - مكرونة طازجة مقلية في صلصة كريمية بالترافل مع فطر بري.",
    "specialty3": "كيكة الشوكولاتة السائلة - كيكة شوكولاتة غنية مع مركز سائل، تُقدم مع آيس كريم الفانيليا.",
    "funFacts": "حقائق ممتعة",
    "fact1": "تأسس مطعم ديب في عام 2015 على يد الشيف علي ديب، الذي لديه أكثر من 20 عامًا من الخبرة في الطهي.",
    "fact2": "نستضيف موسيقى حية كل ليلة جمعة، featuring artists محليين.",
    "fact3": "حصل مطعمنا على جائزة 'أفضل مطعم جديد' من جوائز الطعام في المدينة في عام 2016.",
    contactUs: 'اتصل بنا',
    contactDescription: 'لا تتردد في التواصل معنا عن طريق ملء النموذج أدناه.',
    name: 'الاسم',
    email: 'البريد الإلكتروني',
    message: 'الرسالة',
    enterName: 'أدخل اسمك',
    enterEmail: 'أدخل بريدك الإلكتروني',
    enterMessage: 'أدخل رسالتك',
    submit: 'إرسال',
    formSubmitted: 'تم إرسال النموذج!',
    
  },
  en: {
    chooseLanguage: 'Choose Your Language',
    helloWorld: 'Hello, World!',
    categories: {
      pizza: 'Pizza',
      drinks: 'Drinks',
      desserts: 'Deserts',
      combo: 'Combos',
      burgers: 'Burgers',
    },
    "yourData": "Your Data",
    "nameLabel": "Name*",
    "orderType": "Order Type",
    "namePlaceholder": "Enter your name",
    "nameError": "Name is required",
    "cellphoneLabel": "Cellphone*",
    "cellphonePlaceholder": "Enter your cellphone",
    "cellphoneError": "Cellphone is required and must start with 07",
    "addressLabel": "Address*",
    "addressPlaceholder": "Enter your address",
    "addressError": "Address is required",
    "removeItemConfirmation": "Do you want to remove this item from the cart?",
    "yesRemove": "Yes, remove",
    address : "Address",
    offer: 'Offer',
    currency: 'IQD', // IQD symbol in English
    menu: 'Menu',
    order: 'Order Now',
    contact: 'Contact Us',
    about: 'About Us',
    videoNotSupported: 'Your browser does not support the video tag.',
    welcomeMessage: 'Welcome to our restaurant!',
    addObservation: "Add observation",
    cancel: "Cancel",
    save: "Save",
    goBack: "Go Back",
    addToCart: "Add to Cart",
    quantity: "Quantity",
    observations: "Observations",
    yourOrder: "Your Order",
    cartEmpty: "Your cart is still empty :(",
    total: "Total",
    checkout: "Checkout",
    table: "Table",
    delivery: "Delivery",
    tableNumber: "Table Number",
    tableNumberLabel: "Table Number*",
    tableNumberPlaceholder: "Enter table number",
    tableNumberError: "Table number is required",
    streetLabel: "Street*",
    streetPlaceholder: "Enter your street",
    streetError: "Street is required",
    cityLabel: "City*",
    cityPlaceholder: "Enter your city",
    cityError: "City is required",
    cepLabel: "Postal Code*",
    cepPlaceholder: "Enter your postal code",
    cepError: "Postal code is required",
    restaurantName: "Restaurant Dib",
    client: "Client",
    contact: "Contact",
    order: "Order",
    editAddress: "Edit address",
    addAddress: "Add address",
    payment: "Payment",
    paymentMethod: "Payment Method",
    creditCard: "Credit Card",
    cash: "Cash",
    completeOrder: "Complete Order",
    invalidAddressMessage: "A valid address is required for delivery.",
    emptyCartMessage: "Your cart is empty.",
    "aboutUs": "About Us",
    "aboutDescription": "Welcome to Dib's Kitchen, where culinary dreams come true! Located in the heart of the city, we offer a delightful fusion of traditional and modern dishes that cater to every palate. Our chefs use only the freshest ingredients to create mouthwatering meals that will leave you wanting more.",
    "mission": "Our Mission",
    "missionStatement": "At Dib's Kitchen, our mission is to provide an unforgettable dining experience that combines exceptional food, warm hospitality, and a cozy atmosphere. We believe in using locally sourced ingredients and supporting our community.",
    "specialties": "Our Specialties",
    "specialty1": "Signature Dib's Burger - A juicy beef patty topped with cheddar cheese, crispy bacon, and our special sauce.",
    "specialty2": "Truffle Pasta - Fresh pasta tossed in a creamy truffle sauce with wild mushrooms.",
    "specialty3": "Chocolate Lava Cake - A rich chocolate cake with a gooey molten center, served with vanilla ice cream.",
    "funFacts": "Fun Facts",
    "fact1": "Dib's Kitchen was founded in 2015 by Chef Ali Dib, who has over 20 years of culinary experience.",
    "fact2": "We host live music every Friday night, featuring local artists.",
    "fact3": "Our restaurant has been awarded 'Best New Restaurant' by the City Food Awards in 2016.",
    contactUs: 'Contact Us',
    contactDescription: 'Feel free to get in touch with us by filling out the form below.',
    name: 'Name',
    email: 'Email',
    message: 'Message',
    enterName: 'Enter your name',
    enterEmail: 'Enter your email',
    enterMessage: 'Enter your message',
    submit: 'Submit',
    formSubmitted: 'Form has been submitted!',
  }
};

const i18n = new VueI18n({
  locale: localStorage.getItem('language') || 'ar', // Default language
  fallbackLocale: 'ar',
  messages,
});

export default i18n;