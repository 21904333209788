<template>
  <div class="language-selector">
    <video autoplay muted loop class="background-video">
      <source src="https://videos.pexels.com/video-files/2832316/2832316-hd_1920_1080_30fps.mp4" type="video/mp4">
      Your browser does not support the video tag.
    </video>
    <div class="overlay"></div>
    <div class="content">
      <h1>{{ $t('chooseLanguage') }}</h1>
      <div class="buttons">
        <button @click="setLanguage('en')" class="primary-button">English</button>
        <button @click="setLanguage('ar')" class="primary-button">العربية</button>
      </div>
      <p>{{ message }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      message: ''
    };
  },
  methods: {
    setLanguage(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem('language', lang);
      this.message = this.$t('helloWorld');
      this.$router.push('/menu'); // Redirect to /menu after language selection
    }
  },
  mounted() {
    // Check local storage for language preference
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      this.setLanguage(savedLanguage); // Set language if already saved
    }
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

.background-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -2;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

.language-selector {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
  position: relative;
  overflow: hidden;
  font-family: 'Poppins', sans-serif;
}

.content {
  position: relative;
  z-index: 2;
  text-align: center;
  max-width: 600px;
  width: 90%;
  padding: 40px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
  animation: fadeIn 1.5s ease-out;
}

h1 {
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 30px;
  animation: slideInFromTop 1s ease-out;
}

.buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  flex-wrap: wrap;
}

.primary-button {
  background: linear-gradient(45deg, #FF2351, #FDC844);
  border: none;
  border-radius: 50px;
  font-weight: 600;
  font-size: 1.2rem;
  color: white;
  padding: 15px 40px;
  cursor: pointer;
  margin: 10px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  min-width: 180px;
}

.primary-button:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
}

.primary-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(253, 200, 68, 0.5);
}

p {
  font-size: 1.5rem;
  margin-top: 20px;
  animation: fadeIn 2s ease-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideInFromTop {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 767px) {
  .content {
    padding: 30px;
  }
  
  h1 {
    font-size: 2.5rem;
  }
  
  p {
    font-size: 1.2rem;
  }
  
  .primary-button {
    font-size: 1rem;
    padding: 12px 30px;
    min-width: 150px;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 2rem;
  }
  
  .primary-button {
    width: 100%;
    margin: 10px 0;
  }
}
</style>