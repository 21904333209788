<template>
  <div class="invalid-address-modal">
    <span class="icon" v-html="warningIcon"></span>
    <p>{{ $t('invalidAddressMessage') }}</p>
    <button @click="$emit('on-modal-close')" class="close-button">{{ $t('close') }}</button>
  </div>
</template>

<script>
import feather from "feather-icons";

export default {
  name: "InvalidAddressModal",
  computed: {
    warningIcon() {
      return feather.icons["alert-triangle"].toSvg();
    },
  },
};
</script>

<style scoped lang="less">
.invalid-address-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;

  .icon {
    margin-bottom: 10px;
    width: 24px;
    height: 24px;
  }

  p {
    margin: 0 0 10px;
    font-size: 14px;
    color: #333;
  }

  .close-button {
    background-color: #f0f0f0;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;

    &:hover {
      background-color: #e0e0e0;
    }
  }
}
</style>