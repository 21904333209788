<template>
  <div class="items-list">
    <Loading v-if="isLoading" />
    <template v-else>
      <Item v-for="item in itemsList" :key="item.id" :item="item" />
      <p v-if="error" class="error-message">{{ $t('errorLoadingItems') }}</p>
      <p v-if="!isLoading && !error && itemsList.length === 0" class="no-items-message">{{ $t('noItemsAvailable') }}</p>
    </template>
  </div>
</template>

<script>
import Item from "./Item";
import Loading from "@/components/Loading";

export default {
  name: "ItemsList",
  components: {
    Item,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      error: null,
    };
  },
  computed: {
    itemsList() {
      return this.$store.getters.getItemsList;
    },
    selectedCategory() {
      return this.$store.state.selectedCategory;
    },
  },
  watch: {
    selectedCategory: {
      handler: 'getItemsList',
      immediate: true
    },
  },
  methods: {
    async getItemsList() {
      this.isLoading = true;
      this.error = null;
      try {
        await this.$store.dispatch("fetchItems");
      } catch (err) {
        console.error("Error fetching items:", err);
        this.error = err;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>




<style lang="less" scoped>
.items-list {
  width: 100%;
  margin: 30px 50px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.error-message {
  color: red;
  text-align: center;
  margin-top: 20px;
}

.no-items-message {
  text-align: center;
  margin-top: 20px;
}

@media @small-desktops {
  .items-list {
    width: 100%;
    max-width: 800px;
    margin: 50px auto;
    justify-content: center;
  }
}

@media @smartphones {
  .items-list {
    margin: 0px;
    padding: 20px;
  }
}
</style>
