import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home/Home";
import SelectLanguage from "../views/SelectLanguage/SelectLanguage";
import Payment from "../views/Payment/Payment";
import Cart from "../views/Cart/Cart";
import AddToCart from "../views/AddToCart/AddToCart";
import AboutUS from "@/views/AboutUS.vue";
import Contact from "@/views/Contact.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "SelectLanguage",
    component: SelectLanguage,
  },
  {
    path: "/menu",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: AboutUS,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/payment",
    name: "Payment",
    component: Payment,
  },
  {
    path: "/cart",
    name: "Cart",
    component: Cart,
  },
  {
    path: "/addToCart/:id",
    name: "AddToCart",
    component: AddToCart,
    props: true,
  },
];

const router = new VueRouter({
  routes,
});

export default router;