import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    selectedCategory: "",
    itemsList: [],
    cartList: [],
    item: null,
  },
  mutations: {
    setItemsList(state, items) {
      state.itemsList = items;
    },
    setItem(state, item) {
      state.item = item;
    },
    changeCategory(state, category) {
      state.selectedCategory = category;
    },
    addToCart(state, el) {
      const existingItem = state.cartList.find((cartItem) => cartItem.id === el.id);
      if (existingItem) {
        existingItem.quantity += el.quantity || 1;
      } else {
        state.cartList.push({ ...el, quantity: el.quantity || 1 });
      }
    },
    removeFromCart(state, index) {
      state.cartList.splice(index, 1);
    },
    increaseQuantity(state, { index, quantity = 1 }) {
      state.cartList[index].quantity += quantity;
    },
    decreaseQuantity(state, index) {
      if (state.cartList[index].quantity > 1) {
        state.cartList[index].quantity--;
      }
    },
    addObservation(state, { index, observations }) {
      state.cartList[index].observations = observations;
    },
    resetCartList(state) {
      state.cartList = [];
    },
  },
  actions: {
    changeCategory({ commit }, category) {
      commit("changeCategory", category);
    },
    async fetchItems({ commit, state }) {
      try {
        const response = await axios.get(`/db.json`);
        const items = response.data[state.selectedCategory];
        commit("setItemsList", items);
      } catch (error) {
        console.error("Error fetching items: ", error);
      }
    },
    async fetchItem({ commit, state }, id) {
      try {
        const response = await axios.get(`/db.json`);
        const items = response.data[state.selectedCategory];
        const item = items.find((item) => item.id === id);
        commit("setItem", item);
      } catch (error) {
        console.error("Error fetching item: ", error);
      }
    },
    addToCart({ state, commit }, el) {
      const index = state.cartList.findIndex((cartItem) => cartItem.id === el.id);
      if (index === -1) {
        commit("addToCart", el);
      } else {
        commit("increaseQuantity", { index, quantity: el?.quantity || 1 });
      }

      if (el.observations) {
        commit("addObservation", {
          index,
          observations: el.observations,
        });
      }
    },
    removeFromCart({ state, commit }, id) {
      const index = state.cartList.findIndex((cartItem) => cartItem.id === id);
      if (index !== -1) commit("removeFromCart", index);
    },
    increaseQuantity({ state, commit }, id) {
      const index = state.cartList.findIndex((cartItem) => cartItem.id === id);
      commit("increaseQuantity", { index });
    },
    decreaseQuantity({ state, commit }, id) {
      const index = state.cartList.findIndex((cartItem) => cartItem.id === id);
      commit("decreaseQuantity", index);
    },
    resetCartList({ commit }) {
      commit("resetCartList");
    },
  },
  getters: {
    getItemsList(state) {
      return state.itemsList;
    },
    getItem(state) {
      return state.item;
    },
    getCartTotal(state) {
      return state.cartList.reduce((acc, item) => {
        return acc + item.price * item.quantity;
      }, 0);
    },
  },
});
