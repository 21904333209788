<template>
  <div class="item--quantity">
    <button
      class="buttons"
      @click="onDecreaseButtonClick"
      :disabled="item.quantity === 0 || doNotRemoveFromCart"
    >
      -
    </button>
    <span class="number">{{ item.quantity }}</span>
    <button class="buttons" @click="onIncreaseButtonClick">+</button>
    <Modal :show="showModal" @on-modal-close="onCancelButtonClick">
      <div class="modal-content">
        <h2>{{ $t('removeItemConfirmation') }}</h2>
        <button class="secondary-button" @click="onCancelButtonClick">
          {{ $t('cancel') }}
        </button>
        <button class="primary-button" @click="onRemoveButtonClick">
          {{ $t('yesRemove') }}
        </button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Modal from "./Modal.vue";

export default {
  name: "Quantity",
  components: {
    Modal,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    useStore: {
      type: Boolean,
      default: true,
    },
    doNotRemoveFromCart: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    ...mapActions(["increaseQuantity", "decreaseQuantity", "removeFromCart"]),
    onDecreaseButtonClick() {
      if (this.useStore) {
        if (this.item.quantity === 1) {
          this.showModal = true; // Show modal if trying to remove the last item
        } else {
          this.decreaseQuantity(this.item.id); // Just decrease if quantity > 1
        }
        return;
      }

      if (this.item.quantity > 1) {
        this.item.quantity--;
      } else {
        this.removeFromCart(this.item.id); // Remove item directly if quantity is 1
      }
    },
    onIncreaseButtonClick() {
      if (this.useStore) {
        this.increaseQuantity(this.item.id);
      } else {
        this.item.quantity++;
      }
    },
    onCancelButtonClick() {
      this.showModal = false; // Close the modal without action
    },
    onRemoveButtonClick() {
      this.showModal = false; // Close the modal
      this.$nextTick(() => {
        this.removeFromCart(this.item.id); // Remove the item from cart
      });
    },
  },
};
</script>

<style scoped lang="less">
.item--quantity {
  display: flex;
  align-items: center;
  padding-right: 20px;

  .number {
    font-weight: 500;
    font-size: 16px;
    color: @yellow;
    width: 24px;
    text-align: center;
  }

  .buttons {
    background: none;
    border: none;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;

    &:focus {
      outline: 0;
    }
  }

  .modal-content {
    text-align: center;

    h2 {
      font-size: 22px;
      margin-top: 0;
    }

    button {
      margin-left: 10px;
      margin-top: 20px;
    }
  }
}

@media @tablets {
  .modal-content {
    button {
      padding: 8px 20px;
    }
  }
}
</style>