<template>
  <div class="item" :class="{ 'rtl': isArabic }">
    <Quantity :item="item" class="item--quantity" />
    <div class="item--img-container">
      <img class="item--img" :src="imagePath" alt="" />
    </div>
    <div class="content">
      <h3 class="item--name">{{ localizedName }}</h3>
      <a class="item--observation" @click="onShowObservationModal">
        {{ $t('addObservation') }}
      </a>
      <p class="item--observation-text">{{ item.observations }}</p>
    </div>
    <p class="item--price">{{ formatCurrency(item.price) }}</p>
    <Modal
      :show="showObservationModal"
      @on-modal-close="onCloseObservationModal()"
    >
      <div class="modal-content">
        <h2>{{ $t('addObservation') }}</h2>
        <textarea v-model="item.observations" rows="8"></textarea>
        <div class="button-container">
          <button class="modal-button secondary-button" @click="onCloseObservationModal">
            {{ $t('cancel') }}
          </button>
          <button class="modal-button primary-button" @click="saveObservation">
            {{ $t('save') }}
          </button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Quantity from "@/components/Quantity";
import Modal from "@/components/Modal";

export default {
  name: "CartItem",
  components: {
    Quantity,
    Modal,
  },
  props: {
    item: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      showObservationModal: false,
    };
  },
  computed: {
    selectedCategory() {
      return this.$store.state.selectedCategory;
    },
    imagePath() {
      return require(`@/assets/images/${this.item.id}.png`);
    },
    localizedName() {
      return this.item.name[this.$i18n.locale] || this.item.name.en;
    },
    isArabic() {
      return this.$i18n.locale === 'ar';
    }
  },
  methods: {
    onShowObservationModal() {
      this.showObservationModal = true;
    },
    onCloseObservationModal() {
      this.showObservationModal = false;
    },
    saveObservation() {
      this.$store.dispatch("addObervation", this.item);
      this.showObservationModal = false;
    },
    formatCurrency(value) {
      if (!value) return;
      return value.toLocaleString(this.$i18n.locale, {
        style: 'currency',
        currency: this.isArabic ? 'IQD' : 'USD',
        minimumFractionDigits: 2,
      });
    },
  },
};
</script>

<style scoped lang="less">
.item {
  display: flex;
  padding: 20px 0;
  border-bottom: 1px solid @light-grey;

  &--img-container {
    background: @light-yellow;
    border-radius: 8px;
    width: 70px;
    height: 66px;
    display: flex;
    align-items: center;
  }

  &--img {
    width: 65px;
    display: block;
    margin: auto;
  }

  &--name {
    font-weight: 600;
    font-size: 14px;
    margin: 0;
  }

  &--observation {
    font-weight: 500;
    font-size: 12px;
    color: @dark-grey;
    text-decoration: underline;
    cursor: pointer;
  }

  &--observation-text {
    font-size: 11px;
    color: @dark-grey;
    margin-bottom: 0;
    margin-top: 5px;
    word-break: break-all;
  }

  .content {
    flex-grow: 1;
    padding: 0 20px;
  }

  &--price {
    font-weight: 600;
    font-size: 16px;
    line-height: 27px;
    color: @yellow;
    min-width: 80px;
    text-align: right;
  }

  .modal-content {
    text-align: center;

    h2 {
      font-size: 22px;
      margin-top: 0;
    }

    textarea {
      width: 80%;
      margin-bottom: 20px;
    }

    .button-container {
      display: flex;
      justify-content: center;
      gap: 15px;
    }

    .modal-button {
      width: 120px;
      height: 40px;
      font-size: 16px;
      border-radius: 4px;
      border: none;
      cursor: pointer;
      transition: background-color 0.3s;
    }

    .secondary-button {
      background-color: #f0f0f0;
      color: #333;

      &:hover {
        background-color: #e0e0e0;
      }
    }

    .primary-button {
      background-color: @yellow;
      color: white;

      &:hover {
        background-color: darken(@yellow, 10%);
      }
    }
  }

  &.rtl {
    direction: rtl;
    text-align: right;

    .content {
      padding: 0 20px 0 0;
    }

    &--price {
      text-align: left;
    }

    .modal-content {
      .button-container {
        flex-direction: row-reverse;
      }
    }
  }
}

@media @tablets {
  .item {
    flex-wrap: wrap;
    padding: 12px;
    border: 1px solid @light-grey;
    border-radius: 8px;

    & + .item {
      margin-top: 15px;
    }

    &--img-container {
      order: 1;
    }

    .content {
      order: 2;
      width: calc(100% - 70px);
    }

    &--quantity {
      order: 3;
      padding: 0;
      width: 70px;
      justify-content: center;
    }

    &--price {
      order: 4;
      padding: 0 20px;
      margin: 0;
    }

    &.rtl {
      .content {
        padding: 0 12px 0 0;
      }

      &--price {
        padding: 0 0 0 20px;
      }
    }
  }
}
</style>